import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

  
export default function ControlledOpenSelect(props) {   
  const [val] = React.useState('');
  //const [val, setVal] = React.useState('');
  const [open, setOpen] = React.useState(false);  
  {  

    // Prevent selecting off the showcase for now
    const handleChange = (event) => {
    // setVal(event.target.value);
    return;
    };

    const handleClose = () => {
      setOpen(false);
    };

    const handleOpen = () => {
      setOpen(true); 
    };

    {
    const menuItems = props.listitems.map((val) => <MenuItem value={val} key={val}>{val}</MenuItem>);   
    const placeholderStr = props.placeholder;
    <Autocomplete
    id="combo-box-demo"
    options={menuItems}
    getOptionLabel={(option) => option.title}
    style={{ width: 300 }}
    renderInput={(params) => <TextField {...params} label={placeholderStr} variant="outlined" />}
    />

    return (
      <div className="store-item-input-wrapper" >
          <Select 
            placeholder={placeholderStr}
            labelId={`${props.listitems.length}-weaps-label`}
            id={`${props.listitems.length}-weaps-label-select`}            
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            value={val}
            onChange={handleChange}>  
            {menuItems}
          </Select>
      </div>
    );
  }
  }
}

