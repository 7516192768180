import React from 'react';
import { useRef,useCallback, useEffect } from "react";
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

export default function TopNav() {
    //console.log('TopNav keycloak = ' , authClient);
    const navRef = useRef();
    const handler = useCallback((e) => {
        if (navRef.current.className.includes("show") && !e.target.classList.contains("navbar-toggler") && !e.target.classList.contains("navbar-toggler-icon"))
        document.querySelector(".navbar-toggler").click();
        else
        return;
    }, []);
    useOnClickOutside(navRef, handler);

    return <section className="navbar-area">
    <div className="top-container">
        <div className="row">
            <div className="col-lg-12 full-width">


    <Navbar expand="lg">
  <Navbar.Brand href="#home">
    <div><a className="navbar-logo" href="https://pandahut.net"> </a>
    <div className="logo-website-name">PANDAHUT<sup>NETWORK</sup></div></div>
</Navbar.Brand>
  <Navbar.Toggle aria-controls="basic-navbar-nav" />
  <Navbar.Collapse ref={navRef} className="collapse navbar-collapse main-menu-abs-center sub-menu-bar" id="basic-navbar-nav">
    <Nav className="mr-auto">
        <Nav.Link className="page-scroll" href="/#Home">Home</Nav.Link>
        <Nav.Link className="page-scroll" href="https://pandahut.net/forums">Forums</Nav.Link>
        <Nav.Link className="page-scroll" href="https://pandahut.net/servers">Servers</Nav.Link>
        <Nav.Link className="page-scroll" href="#store">Store</Nav.Link>
        <Nav.Link className="page-scroll" href="https://stats.pandahut.net">Stats</Nav.Link>
        <Nav.Link className="page-scroll" href="https://pandahut.net/discord">Discord</Nav.Link>
    </Nav>
    {/* <Form inline>
      <FormControl type="text" placeholder="Search" className="mr-sm-2" />
      <Button variant="outline-success">Search</Button>
    </Form> */}
  </Navbar.Collapse>
</Navbar>


            </div>
        </div>
    </div>
</section>
}

function useOnClickOutside(ref, handler) {
    useEffect(() => {
      const listener = event => {
        if (!ref.current || ref.current.contains(event.target)) {
          return;
        }
        handler(event);
      };
  
      document.addEventListener("mousedown", listener);
      document.addEventListener("touchstart", listener);
  
      return () => {
        document.removeEventListener("mousedown", listener);
        document.removeEventListener("touchstart", listener);
      };
    }, [ref, handler]);
  }