import React from "react";
import { useRef } from "react";
import ReactTooltip from "react-tooltip";
import ControlledOpenSelect from "./dropdown-store";
import ItemInfoModal from "./modal-iteminfo-button";
import ModalDialog from "./modal-payment-button";
import MvpppSubs from "./subscriptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInfoCircle,
  faTimes,
  faCheck,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons'
import Carousel from "react-bootstrap/Carousel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RankInfo from "../json/newranks";
import '../assets/css/user.css';


export default function Store() {
  const animated = " animated";
  //console.log(
  //  "Loaded Ranks: " + RankInfo.Ranks.map((i) => i.RankName).join(", ")
  //);

  const carsouelref = useRef(null);
  const tableRef = useRef(null)

    //iterate or map these when finished. Or keep them expanded here for easier modifications
  const onNextClick = () => {
    tableRef.current.scrollIntoView({ behavior: "smooth" });
    carsouelref.current.next();
  };
  const [serverTypeFilters, setserverTypeFilters] = React.useState([]);


  const OnChangeViewPerksByServerType = (ServerType, boolvalue) => {
    //console.log(ServerType)
    //console.log(`${ServerType} changed to ${boolvalue}`)
    if (boolvalue) {
      setserverTypeFilters(serverTypeFilters.concat(ServerType))
    }
    else {
      setserverTypeFilters(serverTypeFilters.filter(x => x !== ServerType))
    }
  }

  const ResolveSpecialElement = (PerkInfo, SpecificRankPerkInfo, RankInfo) => {
    if (PerkInfo.Name == "Chat Tag Color") {
      return `<div>${RankInfo.ColorName}</div>`;
    // if (PerkInfo.Name == "Chat Tag Color") {
    //   return `<div>${RankInfo.ColorName} <div class="rank-color-palette" style=\"background-color:${RankInfo.Color};\"></div></div>`;
    }
  };

  //iterate or map these when finished. Or keep them expanded here for easier modification
  //return null;
  
  return (
    <section id="store" className="pricing-area" ref={tableRef}>
      <div id="focus" className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-10 col-store-bootstrap">
            <div className="section-title text-center pb-25">
              <h3 className="title">Member Ranks</h3>
              <p className="text">
                By supporting Pandahut you help ensure that all our server
                upkeep costs are covered.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Carousel
        id="slider-store"
        interval={null}
        controls={false}
        touch={false}
        ref={carsouelref}
      >
        <Carousel.Item>
          <div className="container">
            <div className="perks-cat-select-outer-wrap">
              <div className="MuiTypography-body1 pb-10">
                View Perks by Server Type:
              </div>
              <div className="perks-cat-select-wrap">
                <FormControlLabel
                  value="top"
                  control={<Checkbox color="primary" />}
                  label="PvP"
                  labelPlacement="top"
                  onChange = {(event) => OnChangeViewPerksByServerType("PvP", event.target.checked)}
                />
                <FormControlLabel
                  value="top"
                  control={<Checkbox color="primary" />}
                  label="RP"
                  labelPlacement="top"
                  onChange = {(event) => OnChangeViewPerksByServerType("RP", event.target.checked)}
                />
                <FormControlLabel
                  value="top"
                  control={<Checkbox color="primary" />}
                  label="KitPvP"
                  labelPlacement="top"
                  onChange = {(event) => OnChangeViewPerksByServerType("KitPvP", event.target.checked)}
                />
                <FormControlLabel
                  value="top"
                  control={<Checkbox color="primary" />}
                  label="Unturnov"
                  labelPlacement="top"
                  onChange = {(event) => OnChangeViewPerksByServerType("Unturnov", event.target.checked)}
                />
                <FormControlLabel
                  value="top"
                  control={<Checkbox color="primary" />}
                  label="Rust"
                  labelPlacement="top"
                  onChange = {(event) => OnChangeViewPerksByServerType("Rust", event.target.checked)}
                />
                <FormControlLabel
                  value="top"
                  control={<Checkbox color="primary" />}
                  label="Vanilla+"
                  labelPlacement="top"
                  onChange = {(event) => OnChangeViewPerksByServerType("Vanilla+", event.target.checked)}
                />
                <FormControlLabel
                  value="top"
                  control={<Checkbox color="primary" />}
                  label="Semi-Vanilla"
                  labelPlacement="top"
                  onChange = {(event) => OnChangeViewPerksByServerType("Semi-Vanilla", event.target.checked)}
                />
                              <FormControlLabel
                  value="top"
                  control={<Checkbox color="primary" />}
                  label="Creative"
                  labelPlacement="top"
                  onChange = {(event) => OnChangeViewPerksByServerType("Creative", event.target.checked)}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <div id="slideset1" className="pricingtable pricingtable-flat">
                  <div className={"column column-2 store-headers " + animated}>
                    <div className="column-item column-item-header">
                      <img
                      loading='lazy'
                        src="https://pandahut.net/wp-content/uploads/2020/10/vipicon_h_3.png"
                        alt="Header Blank Space"
                        className="pricingtable-header-image-hidden"
                      />
                    </div>
                    <div className="column-item column-item-price price-grey">
                      <span className="price-value">Price (USD)</span>
                    </div>
                    <div className="column-item column-item-body column-item-header">
                      <div className="column-item-data column-first-row-empty no-pad"></div>
                      {RankInfo.RankPerks.filter(x => serverTypeFilters.length === 0 || x.ServerTypeAssociated.length === 0  || serverTypeFilters.some(serverTypefilter => x.ServerTypeAssociated.includes(serverTypefilter))).map((Perk, index) => {
                        return (
                          <div
                            className={`column-item-data ${
                              index % 2 === 1 ? "odd" : "even"
                            } ${Perk.HeaderRowClass} `}
                          >
                          {Perk.Name}
                            <a
                              href="#slideset1"
                              onClick={(e) => e.preventDefault()}
                              className="table-tooltip"
                              data-tip={Perk.HiddenText}
                            >
                              <FontAwesomeIcon icon={faInfoCircle} />
                            </a>
                          </div>
                        );
                      })}
                    </div>
                  </div>

                  {RankInfo.Ranks.map((rank) => {
                    return (
                      <div className={"column column-2 store-item " + animated + ` ${rank.RankName}-Column`}>
                        <div className="column-item column-item-header">
                          <img
                          loading='lazy'
                            src={rank.Icon}
                            alt={rank.RankName + " Rank"}
                            className="pricingtable-header-image"
                          />
                          <div className="pricingtable-header-name">
                            {rank.RankName}
                          </div>
                        </div>
                        <div className="column-item column-item-price price-green">
                          <span className="price-value">{rank.Price}</span>
                        </div>

                        <div className="column-item column-item-body">
                          <div className="column-item-data column-first-row-empty no-pad"></div>

                          {RankInfo.RankPerks.filter(x => serverTypeFilters.length === 0 || x.ServerTypeAssociated.length === 0 || serverTypeFilters.some(filter => x.ServerTypeAssociated.includes(filter))).map((perk, index) => {
                            const TryFindPerk = rank.RankPerks.find(
                              (x) => x.Name === perk.Name
                            );
                            if (TryFindPerk === undefined) {
                              return null;
                            }
                            const RowCSSName = (index % 2 === 1 ? "odd" : "even") + ' ' + TryFindPerk.RowClass;
                            let customParse = null;
                            if (perk.isSpecialElement) {
                                customParse = ResolveSpecialElement(
                                perk,
                                TryFindPerk,
                                rank
                              );
                            }
                            if (perk.Type === "bool") {
                              return (
                                <div
                                  className={`column-item-data ${RowCSSName} no-pad`}
                                >
                                  <div className="label-sm">
                                    <a
                                      href="#slideset1"
                                      onClick={(e) => e.preventDefault()}
                                      className="table-tooltip"
                                      data-tip={perk.HiddenText}
                                    >
                                    {perk.Name}
                                      <FontAwesomeIcon icon={faInfoCircle} />
                                    </a>
                                  </div>
                                  {TryFindPerk.Value ? (
                                    <FontAwesomeIcon icon={faCheck} />
                                  ) : (
                                    <FontAwesomeIcon icon={faTimes} />
                                  )}
                                </div>
                              );
                            } else if (TryFindPerk.Name === "Weapon Kits") {
                              return (
                                <ControlledOpenSelect listitems={TryFindPerk.Value} placeholder={`${rank.PermWeapons} ${rank.RankName} Weapon Kits`}  />
                              );
                            } else if (TryFindPerk.Name === "RP Server Role Kits") {
                                return (
                                    <ItemInfoModal Name={rank.RankName} TwodArray={TryFindPerk.Value} />                                
                                    );
                              } else if (TryFindPerk.Name === "Exclusive RP Roles") {
                                return (
                                    <ControlledOpenSelect placeholder={`${rank.RankName} Roles`} listitems={TryFindPerk.Value} />                                
                                    );
                              }else if (perk.Type === "html") {
                              return (
                                <div
                                className={`column-item-data ${RowCSSName} no-pad`}
                                >
                                  <div className="label-sm">
                                    <a
                                      href="#slideset1"
                                      onClick={(e) => e.preventDefault()}
                                      className="table-tooltip"
                                      data-tip={perk.HiddenText}
                                    >
                                    {perk.Name}
                                      <FontAwesomeIcon icon={faInfoCircle} />
                                    </a>
                                  </div>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: customParse == null ? TryFindPerk.Value : customParse,
                                    }}
                                  />
                                </div>
                              );
                            }

                            return null;
                          })}
                        </div>
                         <ModalDialog rank={rank} className={`Buy-Button-${rank.RankName}`} /> 
                      </div>
                    );
                  })}

                  <div className={"column column-2 store-item " + animated}>
                    <div className="column-item column-item-header">
                      <img
                      loading='lazy'
                        src="https://pandahut.net/wp-content/uploads/2021/06/mvpppicon_h8.png"
                        alt="MVP++ Rank"
                        className="pricingtable-header-image gold-img"
                      />
                      <div className="pricingtable-header-name">
                        MVP++
                      </div>
                    </div>
                    <div className="column-item column-item-price price-gold">
                      <span className="price-value">
                      $8.99 USD
                      </span>
                      <span className="pt-pd">&nbsp;/ Month</span>
                    </div>
                    <div className="column-item column-item-body">
                      <div className="column-item-data column-first-row-empty no-pad"></div>
                      <div className="column-item-data odd no-pad">
                        <div className="label-sm">
                          <a
                            href="#slideset1"
                            onClick={(e) => e.preventDefault()}
                            className="table-tooltip"
                            data-tip={
                              "Applies to all Pandahut Unturned Servers."
                            }
                          >
                          Reserved Slot
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </a>
                        </div>
                        <FontAwesomeIcon icon={faCheck} />

                      </div>
                      <div className="column-item-data odd no-pad">
                        <div className="label-sm">
                          <a
                            href="#slideset1"
                            onClick={(e) => e.preventDefault()}
                            className="table-tooltip"
                            data-tip={
                              "Applies to all Pandahut Unturned Servers."
                            }
                          >
                          Chat Tag Color
                            <FontAwesomeIcon icon={faInfoCircle} />
                          </a>
                        </div>
                        <div className="column-item-data even">
                        You Pick!
                      </div>
                      </div>
             
                      <div className="column-item-data even store-item-input-wrapper">
                        Requires MVP+
                      </div>
                    </div>
                    
                    <a
                      href="/#mvppp-div"
                      data-target="#slider-store"
                      className="modal-btn-wrapper"
                      onClick={(e) => {
                        e.preventDefault();
                        onNextClick();
                      }}
                    >
                      <div className="column-item column-item-footer">
                        <span className="column-footer-buy-btn">
                          More Info &nbsp;{" "}
                          <FontAwesomeIcon icon={faChevronRight} />
                          <FontAwesomeIcon icon={faChevronRight} />
                        </span>
                      </div>
                    </a>
                  </div>
                  <ReactTooltip
                    disableFocusListener
                    className="tooltip-node tip-list"
                    delayHide={1000}
                    effect="solid"
                  />
                </div>
              </div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item>
          <MvpppSubs servtype="PvP" refCarousel={carsouelref} className="displaynone" />
        </Carousel.Item>
      </Carousel>
    </section>
  );
}

String.prototype.toTitle = function () {
  return this.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1);
  });
};
