
import '../assets/css/bootstrap.min.css'
import '../assets/images/favicon.png'
import '../assets/images/logo192.png';
import '../assets/css/magnific-popup.css';
import '../assets/css/slick.css';
import '../assets/css/LineIcons.css';
import '../assets/css/default.css';
import '../assets/css/style.css';
import '../assets/css/user.css';
import '../assets/css/charts.css';
import '../assets/css/forms.css';
import '../assets/css/modal.css';
import '../assets/css/subscriptions.css';
import '../assets/css/loadfont.css';
import React from "react";
import AppHead from "../components/app-head";
import TopNav from "../components/section-top-nav";
import HeroSectionTop from "../components/section-hero-top";
import AboutUsSection from "../components/section-aboutus";
//import Store from './components/section-store';
//import ContactUsSection from './components/section-contactus';
//import FooterSection from './components/section-footer';



//const Store = universal(() => import("../components/section-store"));
//const ContactUsSection = universal(() => import("./components/section-contactus"));
import Store from "../components/section-store";
import ContactUsSection from "../components/section-contactus";

//const FooterSection = universal(() => import("../components/section-footer"));
import FooterSection from "../components/section-footer";





export default class App extends React.PureComponent {

  constructor(props) {
    super(props);

    // this.state = { layout };
    //console.log("this.state.layout = ", this.state.layout);
    //console.log("this.props = ", this.props);

  }


  





  render() {
      return (
        <React.StrictMode>
          <div className="body-wrapper">
           <AppHead />
            <TopNav />
            <HeroSectionTop />
            <AboutUsSection />
            <Store />
            <ContactUsSection />
            <FooterSection />
            </div>
        </React.StrictMode>
      );
    } 
}

//import('./Hook').then(fn => fn.default(App));
